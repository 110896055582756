hr
{
  height: 1px;
width: 100%;
  background-color: #0e293f;

}


.head{
  margin-bottom: 0px;
  color: rgb(5, 60, 60);
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif sans-serif;
  font-size: 2.4em;
  font-weight: normal;
  text-align: center;

}
.head:hover {
  
  font-size: 3.4em;
}


.maison {
  padding: 15px;
  margin: 10px auto;
  font-family: Georgia, Times, serif;
  width: 100%;
  max-width: 1300px; /* Added a max-width to prevent the content from becoming too wide on larger screens */
  border-left: 4px outset #333; /* Simplified border styles */
  border-right: 4px inset #333;
  border-bottom: 2px ridge #666;
}
.home2{
  width: 100%;
  margin-top: 1px;
  margin-bottom: 20px;
  height: auto;
}
.home{
  display: flex;
  flex-direction: row;
  overflow: hidden;
   }


.him {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: 0.5s;
}
.him:hover {
  opacity: 0.8;
  filter: blur(1px);

}

.text1{
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 25px;
  color: #333333;
  line-height: 2;
  text-shadow: 0 0 2px #FFFFFF;
  letter-spacing: 0.3px;
}
.text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.3px;
  color: #0e293f;
  text-shadow: 0 0 4px #FFFFFF;
  margin-top: 0;
  margin-bottom: 15px;
  hyphens: auto;
  text-align: justify;
}


.mid_vid {
    width: 100%;
    height: 300px;
}


.video_wrapper {
    height: 500px;
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}


.cont {
    position: relative;
    color: aliceblue;
    text-shadow: 1px 1px 10px;
}


.im1 {
    padding: 1rem;
    height: 29rem;
    width: 40rem;
    object-fit: cover;
}


.image {
    padding: 1rem;
    height: 25rem;
    width: 100%;
    object-fit: cover;
}
.image:hover {
  opacity: 0.8;
  filter: blur(1px);

}


.side_word {
    width: 50%;
    padding: 0% 2% 0% 0%;
  
}
.stories{

  height: 500px;
  width: 95%;
  position: relative;
  overflow: hidden;
 
  display: flex;

  justify-content: center;

}

@media only screen and (max-width: 600px ) {
  hr{
    height: 1px;
  width: 100%;
    background-color: #003666;
  }
  .stories{

    height: 300px;
    width: 100%;
    position: relative;
    overflow: hidden;
   
    display: flex;
  
    justify-content: center;
  
  }
  .home{
    display: flex;
    flex-direction: column;
   
     }

  
 
    .video{
  height: 100%;
  margin-left: 0px;
  width: 100%;

    }
    video::-webkit-media-controls {
      display: none;
    }

  .maison {
      padding: 0.5rem;
      max-width: 900px; 
      margin: 0px;
      font-family: Georgia, 'Times New Roman', Times, serif;
      border-left-style: none ;
      border-right-style: none ;
      border-bottom-style: ridge ;
      width: 98%;
      overflow: hidden;
  }
  .head{
    margin-bottom: 0px;
    color:  rgb(5, 60, 60);;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif sans-serif;
    font-size: 1.5em;
    font-weight: normal;
    text-align: center;
  
  }
  .head:hover {
  
    font-size: 1.8em;
  }
  .image {
    padding: 1rem;
    height: 18rem;
    width: 97%;
    object-fit: cover;
    
}

  .text1{
    word-spacing: 0.8px;
    font-size: 17px;
    line-height: 1.7;
  

  }

  .text {
    word-spacing: 0.8px;
    font-size: 17px;
    line-height: 1.7;
  }

  .im1 {
    padding: 1rem;
    height: 25rem;
    width: 40rem;
    object-fit: cover;
  }

 

  .him {
    padding: 1rem;
    height: 15rem;
    width: 20rem;
    object-fit: cover;
  }
  .side_word {
    width: 100%;
    line-height: 1;
    
  }

}
@media only screen and (max-width: 1150px ) {
  
  .imagex {
    padding: 0.5rem;
        height: 15rem;
        width: 98%;
        object-fit: cover;
}
  .image {
    padding: 0.5rem;
        height: 15rem;
        width: 100%;
        object-fit: cover;
}
  .home{
 display: flex;
 flex-direction: column;
 overflow: hidden;
  }
  .home2{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1px;
    margin-bottom: 20px;
    height: auto;
  }
  .side_word {
    width: 100%;
    line-height: 1;
    
  }
  
}


#progressBarContainer {
  position: fixed;
  z-index: 10;
  background: rgba(255,255,255,0.05);
  width: 100%;
  top: 70px;
  left: 0;
}

#progressBar {
  background: linear-gradient(to left, rgb(11, 11, 11), rgb(3, 33, 64));
  transform-origin: top left;
  transform: scaleX(0);
  opacity: 0;
}

#progressBarContainer,
#progressBar {
  height: 10px;
}
