
.foots{ padding: 2px; position: sticky; top: 0; background-color:  rgb(254, 253, 252); border-radius: 15px; -webkit-border-radius: 15px; -moz-border-radius: 15px; -ms-border-radius: 15px; -o-border-radius: 15px;
}
.headed{
	margin-bottom: 0px;
	color: #031924;
	font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif sans-serif;
	font-size: 3.4em;
	font-weight: normal;
	text-align: center;
  
  }

.footer{
	background-color: #031924;
    padding: 0px 0;
	padding-bottom: 300px;
	
	

}
.footer-col{
   width: 100%;
  
}
.like li {
	font-size: 18px;
	text-transform: capitalize;
	color:  #031924;;
	text-decoration: none;
	font-weight: 300;
	color:  #031924;;
	display: block;
	transition: all 0.3s ease;
}
li:hover{
	color: #302a4b;
	padding-left: 8px;
}


/*responsive*/
@media(max-width: 767px){
  .footer-col{
    width: 100%;
   
	
}
.like li {
	font-size: 25px;
	text-transform: capitalize;
	color: #191515;
	text-decoration: none;
	font-weight: 300;
	color: #372727;
	display: block;
	transition: all 0.3s ease;
}

}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}

.like li {
	font-size: 15px;
	letter-spacing: px;
	text-transform: capitalize;
	color: #181716;
	text-decoration: none;
	font-weight: 300;
	
	display: block;
	transition: all 0.3s ease;
}
li:hover{
	color: #56555a;
	padding-left: 8px;
}

.foots{
	padding: 2px;
	position: sticky;
	top: 0;
	background-color: rgb(244, 244, 244);
	width: 98%;

	
	
	}
	.headed{
		margin-bottom: 0px;
		color: #031924;
		font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif sans-serif;
		font-size: 2.4em;
		font-weight: bolder;
		
	  }
}

