.button {
  display: inline-block;
  padding: 7px 11px;
  font-size: 1rem;
  color: #FFFFFF;
  margin: 3px;
  background-color: #024556;
  border-radius: 5px;
  border: none;
  box-shadow: 0 3px 16px rgba(80, 75, 75, 0.16);
  transition: all 0.2s;
  min-width: 10rem;
  width: auto;
  text-align: center;
  position: relative;
}

h1 {
  color:  #12202b;
  font-family: Arial, sans-serif;
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 10px;
}


h2 {
  color:  #233546;
  font-family: Arial, sans-serif;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 10px;
}


h3 {
  color:  #162634;
  font-family: Arial, sans-serif;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 10px;
}


h4 {color: #1f3a51;
  font-family: Arial, sans-serif;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 10px;
}


h5 {
  color:  #122230;
  font-family: Arial, sans-serif;
  font-size: 0.6em;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 10px;
}


.link{
  text-decoration: none;
  color: rgb(2, 96, 96);
}




.inner-carousel{
  display:flex;
  
}
.carousel{
  cursor: grab;
  overflow: hidden;
  margin: 2% 5%;
  min-height: 2rem;
  
}

.search{
  background-color: rgb(241, 244, 246);
  height: 3em;
  width: 70%;
  border-radius: 35px;
  border: solid 2px rgb(16, 6, 28);
padding: 3px;
position: sticky;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
}



.sc_1 {
  width: 55%;
  overflow-y: hidden;
  border-radius:35px ;
margin-left: 250px;
  font: 10px;
  border-right-style: solid ;
  border-left-style: solid ;
  height: 350px;
}


.list{
  margin: 0% 1%;
  padding: 2rem;
  height: 100%;
  width: 100%;
font-family: 'Courier New', Courier, monospace;
  width: 85%;
  font-size: medium;
  font-style: oblique;
  overflow: hidden;
  text-align: center;
 
  
  
}

.side {
  height: 100%;
  width: 13%;
  padding: 10px;
  background-color: #fff;
  border-color:  rgb(46, 46, 49);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  font-size: 18px;
  font-family: 'Courier New', Courier, monospace;
  position: unset;
  border-radius: 8px;
  transition: all 250ms ease-in-out;

}

    .im{
      padding: 1rem;
      height: 15rem;
      width: 20rem;
      object-fit: cover;
    }

  
    .home{
      padding: 0% 1% 0% 1%;
      display: flex;


    }
    
    .home2{
      display: flex;

    }
    .causes_home{
      padding: 0% 5% 0% 5%;

    }
    .home_side{
      display: grid;
    

    }


    .imaged{
      
        height: 15rem;
        width: 20rem;
        object-fit: cover;
      
      }
      
  
    .rout{
  display: flex;
  width: 100%;
  padding: 0% 1% 0% 1%;

      }
      .stream{
   
        display: none;
              }
      

    @media (max-width: 650px) {

      .index{
        width: 99%;
      
       
        padding: 4px;
        background-color: #f0f0f0;
      }
      
 
      .im{
        padding: 1rem;
        height: 15rem;
        width: 19rem;
        object-fit: cover;
      }
      header {
        width: 100%;
        position: sticky;
       top: 0px;
        }
  
      .im1{
        padding: 0.5rem;
        height: 15rem;
        width: 100%;
        object-fit: cover;
      }
  
      .home{
        padding: 0% 1% 0% 1%;
        display: grid;
  
  
      }
      
      .home2{
        display: grid;
  
      }
      .causes_home{
        padding: 0% 5% 0% 5%;
  
      }
      .home_side{
        display: grid;
  
      }
      .container {
        text-align: center;
      }
      .container-nav {
        flex-direction: column;
      }
     
      nav ul {
        flex-direction: row;
      }
      .stream{
        padding: 3px 23px 3px 3px;
   
      
       display: block;
              }
      
      
      .side {
        display: none;
        
      }

      .stem{
        width: 100%;
    font-size: 16px;
      }
      .logo{
        height: 4em;
        width: 5em;
        cursor: pointer;
      }
      .space {
        width: 100%;
        text-align: center;
        display: contents;
        
      }
      
      .sc_1 {
        width: 100%;
        text-align: center;
        border-left-style: solid ;
        height: 400px;
        margin-left: 0px;
      }
      .item{
        min-height: 20%;
        min-width: 40%;
        padding: 0.2% 0.1% 0.3% 0.1%;
      }
      .item img{
        width:75%;
        height:60%;
        border-radius: 0.8rem ;
        -webkit-border-radius: 0.8rem ;
        -moz-border-radius: 0.8rem ;
        -ms-border-radius: 0.8rem ;
        -o-border-radius: 0.8rem ;
        pointer-events: none;
}
      .inner-carousel{
        display:flex;
        width: 100;
      }
      .carousel{
        cursor: grab;
        overflow: hidden;
        margin: 4% 2%;
        height: 15rem;
        width: 100%;
        
      }
    
    }

  
    .rend1{
      display: flex;
    }.rend{
      display: flex;
    }
    .line{
  border: 2px solid rgb(181, 169, 169);
  width: 80%;
}
