.NavbarItems{
    display:flex;
    justify-content: space-between;
    background-color:rgb(254, 253, 252);
    font-size: 1.2rem;
    padding: 0 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.25);
    width:99%;
    height:70px;
    border-radius: 13px;
    -webkit-border-radius: 13px;
    -moz-border-radius: 13px;
    -ms-border-radius: 13px;
    -o-border-radius: 13px;
    position: sticky;
    top: 0px;


}
.icon{
align-self: center;
padding-right: 60px;
}

.logo{
    color: rgb(9, 1, 1);
justify-self: start;
cursor: pointer;

}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(6,auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: end;
}
.nav-links{
    text-decoration: none;
    color:rgb(10, 0, 0);
    padding:0.7rem 1rem;
    white-space: nowrap;
}
.nav-links i{
    padding-right: 10px;

}
.nav-links :hover{
    background-color: rgb(80, 80, 238);
    border-radius: 4px;
    transition: all0.2s ease-in-out;
    -webkit-transition: all0.2s ease-in-out;
}
.fa-bars,
.fa-times{
    color: rgb(12, 1, 1);
}
.menu-icons{
    display: none;
}
@media screen and (max-width:900px){
    .NavbarItems{
        z-index: 99;
        background-color: rgb(255, 255, 255);
        width: 98%;
    }
   .nav-menu{
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    background: rgba(255, 255,255, .25);
    box-shadow:0 8px 32px rgba(31,38,135,.37);
    backdrop-filter:blur(4px) ;
    border-radius: 13px;
    width:100%;
    position: absolute;
    top:0;
    left:-110%;
    align-items: stretch;
    padding: 30px 0 10px 0;
    margin: 0;
    z-index: -1;
    transition:all 0.3s ease-in-out ;
    
}
    
.nav-menu.active{
    left: 0%;

}
.nav-links{
    display: block;
    widows: 100%;  
    padding: 2rem 0;
    color:#222
}
.nav-links:hover{
    background: rgba(255, 255,255,0.25);
    backdrop-filter :blur(20px) ;
}
.menu-icons{ display: block;

align-self: center;}

}